import {Group} from "../models/Group";
import {Checkbox, Flex, Table, Tag} from "@lego/klik-ui";
import React from "react";

interface DeviceGroupRowsProps {
    groups: Group[];
    isChecked: { [key: string]: { add: boolean; remove: boolean; color?: string } };
    handleCheck: (event: React.ChangeEvent<HTMLInputElement>, groupId: string) => void;
}
export const DeviceGroupRows = ({ groups, isChecked, handleCheck }: DeviceGroupRowsProps) => {
    return (
        groups.length > 0 ? (
            <Table bg="white">

                <Table.Body>
                    {groups
                        .sort((a, b) => {
                            const aName = a.name.replace(/c1\.appdeploy\.device\./i, '').replace(/^Available\.(.+)/i, (match, appName) => `${appName} - Available`);
                            const bName = b.name.replace(/c1\.appdeploy\.device\./i, '').replace(/^Available\.(.+)/i, (match, appName) => `${appName} - Available`);
                            return aName.localeCompare(bName);
                        })
                        .map((group) => (
                            <Table.Row key={group.id}
                                       style={{backgroundColor: isChecked[group.id]?.color === '#FFF2F2' ? '#FFF2F2' :'#E8f7E6'}}>
                                <Table.Cell w="29%">
                                    {group.name
                                        .replace(/c1\.appdeploy\.device\./i, '')
                                        .replace(/^Available\.(.+)/i, (match, appName) => `${appName} (Available)`)}
                                </Table.Cell>
                                <Table.Cell w="35%">{group.name}</Table.Cell>
                                <Table.Cell w="20%" textAlign="center">
                                    {group.name.toLowerCase().startsWith('c1.appdeploy.device.available.')
                                        ? <Tag colorScheme="information"><Tag.Label
                                            className="tag">Available</Tag.Label></Tag>
                                        : group.name.toLowerCase().startsWith('c1.appdeploy.device.')
                                            ? <Tag colorScheme="warning"><Tag.Label
                                                className="tag">Required</Tag.Label></Tag>
                                            : ''}
                                </Table.Cell>
                                <Table.Cell w="8%" textAlign="center">
                                    
                                </Table.Cell>
                                <Table.Cell w="8%" textAlign="center">
                                    <Flex alignItems="center" justifyContent="center" height="100%">
                                        <Checkbox
                                            name="remove"
                                            colorScheme="error"
                                            borderColor="error.400"
                                            isChecked={isChecked[group.id]?.remove}
                                            isDisabled={isChecked[group.id]?.add}
                                            onChange={(e) => handleCheck(e, group.id)}
                                        />
                                    </Flex>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                </Table.Body>
            </Table>
        ) : (
            <div></div>
        )
    );
}