import React, { useEffect, useState } from "react";
import { getToken } from "../auth/authConfig";
import { useMsal } from "@azure/msal-react";
import { Group } from "../models/Group";
import { Groups } from "../services/Groups";
import { Box, Button, Checkbox, CloseButton, Flex, Grid, InlineNotification, Search, Spinner, Table, Tag, Text, useToast } from "@lego/klik-ui";
import {GroupRows} from "../components/GroupRows";
import {DeviceGroupRows} from "../components/DeviceGroupRows";

type IsCheckedType = {
    [key: string]: {
        add: boolean;
        remove: boolean;
    };
};

type CheckedGroup = {
  color?: string;
  add?: boolean;
  remove?: boolean;
};

type CheckedGroups = {
  [key: string]: CheckedGroup;
};

interface GroupListProps {
    selectedDevice: any, 
    isDeviceMembership: boolean,
    deviceGroups: Group[],
}

export const GroupList: React.FC<GroupListProps> = ({selectedDevice, isDeviceMembership, deviceGroups}) => {

    const { instance } = useMsal();
    const toast = useToast();

    const onCloseComplete = () => {
        console.log('Group selection has been reset.');
    };

    const [groups, setGroups] = useState<Group[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [searchGroup, setSearchGroup] = useState<string>("");
    const [isChecked, setIsChecked] = useState<{ [key: string]: { add: boolean; remove: boolean; color?: string } }>({});
    const [selectedGroupIds, setSelectedGroupIds] = useState<(string | number)[]>([]);
    const [checkedRows, setCheckedRows] = useState<(string | number)[]>([]);
    const [selectedAction, setSelectedAction] = useState<string | null>(null);
    const [showNotification, setShowNotification] = useState(false);

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>, groupId: string) => {
    const { name, checked } = event.target;
    
    let color = 'transparent';
    if (checked) {
        color = name === 'add' ? '#E8f7E6' : '#FFF2F2';
        setSelectedAction(name); // set selected action
    }

    setIsChecked(prevState => ({
        ...prevState,
        [groupId]: { ...prevState[groupId], [name]: checked, color: color },
    }));

    if (checked) {
        const newGroupIds = [...selectedGroupIds, groupId];
        setSelectedGroupIds(newGroupIds);
    } else {
        const newGroupIds = selectedGroupIds.filter(id => id !== groupId);
        setSelectedGroupIds(newGroupIds);
    }
};
    
    const resetSelection = () => {
        setIsChecked(prevState => {
            const newState = { ...prevState };

            for (let key in newState) {
                newState[key] = { add: false, remove: false };
            }

            return newState;
        });

        setSelectedGroupIds([]);
    };

    const isNothingSelected = () => {
        for (let key in isChecked) {
            if (isChecked[key].add || isChecked[key].remove) {
                return false;
            }
        }
        return true;
    };

    const filteredGroups = groups ? groups.filter(group => 
        group.name.toLowerCase().includes(searchGroup.toLowerCase()) 
    ) : [];
    

    const addDevicesToGroups = async () => {
    try {
        const token = await getToken(instance);
        const appService = new Groups();
        if (selectedGroupIds.length === 0) {
            console.log('No groups selected.');
            return false;
        } else {
            await Promise.all(selectedGroupIds.map(groupId =>
                appService.addDevicesToGroups(token.accessToken!, [groupId.toString()], selectedDevice)
            ));
            return true;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
};

const deleteDevicesFromGroups = async () => {
    try {
        const token = await getToken(instance);
        const appService = new Groups();
        if (selectedGroupIds.length === 0) {
            console.log('No groups selected.');
            return false;
        } else {
            await Promise.all(selectedGroupIds.map(groupId =>
                appService.deleteDevicesFromGroups(token.accessToken!, [groupId.toString()], selectedDevice)
            ));
            return true;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
};

// Function to close the notification
const onClose = () => setShowNotification(false);

// Example function that might trigger showing the notification
const completeTasks = () => {
    // Perform tasks...
    setShowNotification(true); // Show the notification on task completion
};

    useEffect(() => {
        getToken(instance).then(token => {
            const appService = new Groups();
            appService.getGroups(token.accessToken!).then(group => {
                setGroups(group)
                setIsLoading(false);
            })
        })
    }, [instance])
    

  return (
    <Flex className="groups-column" flexDirection="column" bg="light-blue.300" p={3}>
        <Grid alignItems="center" templateColumns="repeat(2, 1fr)" mb={3}>
            <Box className="groups-header">
                <Text as="h2" textStyle="2xl" color="white">Group List</Text>
            </Box>
            <Box className="groups-reset-selection" textAlign="right">
                <Button
                    className="savechanges"
                    size="sm"
                    mx={1}
                    bg="success.400"
                    fontWeight={700}
                    borderRadius={0}
                    isDisabled={selectedDevice.length === 0 || selectedGroupIds.length === 0}
                    onClick={async () => {
                        try {
                            await completeTasks();

                            if (selectedAction === 'add') {
                                await addDevicesToGroups();
                            } else if (selectedAction === 'remove') {
                                await deleteDevicesFromGroups();
                            }

                            toast({
                                position: 'bottom-right',
                                duration: 10000,
                                render: ({ onClose }) => (
                                    <InlineNotification variant="success">
                                        <InlineNotification.Content alignItems="flex-start" flexDirection="column">
                                            <InlineNotification.Title>Task Completion</InlineNotification.Title>
                                            <InlineNotification.Description>
                                                All tasks have been completed.
                                            </InlineNotification.Description>
                                        </InlineNotification.Content>
                                        <CloseButton aria-label="Close" onClick={onClose} />
                                    </InlineNotification>
                                ),
                            });
                        } catch (error) {
                            console.error("An error occurred:", error);
                            toast({
                                position: 'bottom-right',
                                duration: 10000,
                                render: ({ onClose }) => (
                                    <InlineNotification variant="error">
                                        <InlineNotification.Content alignItems="flex-start" flexDirection="column">
                                            <InlineNotification.Title>Error</InlineNotification.Title>
                                            <InlineNotification.Description>
                                                An error occurred. Please try again.
                                            </InlineNotification.Description>
                                        </InlineNotification.Content>
                                        <CloseButton aria-label="Close" onClick={onClose} />
                                    </InlineNotification>
                                ),
                            });
                        }
                    }}
                >
                    Save Changes
                </Button>
            </Box>
        </Grid>
        <Box className="group-search" mb={4}>
            <Search
                onChange={(value: string) => {
                    setSearchGroup(value);
                }}

                onEnterPressed={(value: string) => {
                    setSearchGroup(value);
                }}

                size="md" value={searchGroup} isDisabled={isLoading} placeholder="Search for a group"
            />
        </Box>
        <Flex className="group-list-header" flexDirection="column" mb={3}>
            <Grid alignItems="center" templateColumns="repeat(2, 1fr)">
                <Box>
                    <Text as="h4" textStyle="lg" color="white">
                        {selectedGroupIds.length} {selectedGroupIds.length === 1 ? 'group' : 'groups'} selected.
                    </Text>
                </Box>
                <Box textAlign="right">
                    <Button 
                        variant="outline" 
                        size="sm" 
                        color="white" 
                        borderColor="white" 
                        borderRadius="0" 
                        onClick={() => {
                            toast({
                                position: 'bottom-right',
                                duration: 10000,
                                onCloseComplete,
                                render: ({ onClose }) => (
                                    <InlineNotification variant="info">
                                        <InlineNotification.Content alignItems="flex-start" flexDirection="column">
                                            <InlineNotification.Title>Group Selection</InlineNotification.Title>
                                            <InlineNotification.Description>
                                                Group selection has been reset.
                                            </InlineNotification.Description>
                                        </InlineNotification.Content>
                                        <CloseButton aria-label="Close" onClick={onClose} />
                                    </InlineNotification>
                                ),
                            });
                            resetSelection();
                        }} 
                        isDisabled={isNothingSelected()}
                    >
                        Reset
                    </Button>
                </Box>
            </Grid>
        </Flex>

        {isLoading ? (
            <Box p={4}>
                <Spinner color="white" mx="auto" />
                <Text as="h4" textStyle="xl" color="white" fontWeight="bold" textAlign="center" my={2}>Loading groups...</Text>
            </Box>
        ) : (
            <Flex direction="column" p="0">
                <Table bg="white">
                    <Table.Head>
                        <Table.Row fontWeight="bold" borderBottomWidth={1} borderColor="light-blue.300">
                            <Table.Cell w="29%">App Name</Table.Cell>
                            <Table.Cell w="35%">Group Name</Table.Cell>
                            <Table.Cell w="20%" textAlign="center">Group Type</Table.Cell>
                            <Table.Cell w="8%" textAlign="center" color="success.400">ADD</Table.Cell>
                            <Table.Cell w="8%" textAlign="center" color="error.400">REMOVE</Table.Cell>
                        </Table.Row>
                    </Table.Head>
                </Table>
                <div className="scroll-container">
                    <Box className="group-list-container">
                        <DeviceGroupRows groups={deviceGroups} isChecked={isChecked} handleCheck={handleCheck} />
                        <GroupRows groups={filteredGroups} isChecked={isChecked} handleCheck={handleCheck} />
                    </Box>
                </div>
                
            </Flex>
        )}
    </Flex>
  );
};